<template>
  <div class="dataDownCss">
    <Navbar :pageName="pageName" />
    <div class="contentMsgCss">
      <div ref="tabContent" class="tabContentCss">
        <Tab
          lineHeight="2.2rem"
          tabType="vertical"
          :cur="programmeMsgType"
          :tabTitleArr="tabTitleArr"
          isSelectBackColor="white"
          isSelectfontColor="#666666"
          notIsSelectfontColor="white"
          notIsSelectBackColor="#666666"
          @change="tabChange"
        />
      </div>
      <div class="detailMsgCss" :style="{height: contentRightHeight}">
        <div v-for="(item, index) in dataDownList" :key="index">
          <div class="detailMsgTitleCss">{{item.title}}</div>
          <div v-if="item.tableList">
            <Table
              :notHasBorder="true"
              :tableList="item.tableList"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/views/home/components/Navbar.vue'
import Tab from '@/components/myComp/tab/Tab.vue'
import Table from '@/components/myComp/table/Table.vue'

const useDeatil = [ // 使用说明
  {
    title: '使用说明',
    tableList: [ // 表格内容
      {
        tableTrArr: [
          [{ tdName: '资料名称', textAlign: 'center' }, { tdName: '类型', textAlign: 'center' }, { tdName: '上传时间', textAlign: 'center' }, { tdName: '上传者', textAlign: 'center' }, { tdName: '操作', textAlign: 'center' }],
          [{ tdName: '帝图物联网智能采集终端技术规格书', textAlign: 'center' }, { tdName: '使用说明', textAlign: 'center' }, { tdName: '2017-08-15', textAlign: 'center' }, { tdName: 'kuankuan2017	', textAlign: 'center' }, { tdName: '下载', textAlign: 'center', type: 'button', butType: "aBut", url: 'http://file.ditoo.com.cn/jishuguige.pdf' }]
        ]
      }
    ]
  }
]
const prductDetail = [ // 产品介绍
  {
    title: '产品介绍',
  }
]
const SoftRelated = [ // 相关软件
  {
    title: '相关软件',
  }
]
export default {
  components: {
    Navbar,
    Tab,
    Table
  },
  data() {
    return {
      pageName: '',
      contentRightHeight: 'auto', // 右侧容器的高度
      programmeMsgType: 0,
      tabTitleArr: [
        { title: '使用说明', iconName: 'magic' },
        { title: '产品介绍', iconName: 'lifeSaver' },
        { title: '相关软件', iconName: 'wordFile' }
      ],
      dataDownList: useDeatil
    }
  },
  mounted() {
    this.pageName = this.$route.query.pageName
    this.setContentRightHeight()
    window.onresize = () => { // 监听浏览器大小变化
      this.setContentRightHeight()
    }
  },
  methods: {
    setContentRightHeight() { // 设置左侧容器高度
      setTimeout(() => {
        const contentLeftHeight = this.$refs.tabContent.offsetHeight
        this.contentRightHeight = contentLeftHeight + 'px'
      }, 300)
    },
    tabChange(e) {
      this.programmeMsgType = e
      switch(this.programmeMsgType) {
        case 0: this.dataDownList = useDeatil; break
        case 1: this.dataDownList = prductDetail; break
        case 2: this.dataDownList = SoftRelated; break
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.dataDownCss {
  width 99.8rem
  background-color #333439
  font-size 0.8rem
  .contentMsgCss {
    padding 2rem 18rem 4rem 18rem
    .tabContentCss {
      width 10.8rem
      float left
    }
    .detailMsgCss {
      float left
      width 53rem
      background-color white
      .detailMsgTitleCss {
        width 100%
        height 2rem
        line-height 2rem
        text-align center
        font-size 1.2rem
        color #666666
      }
    }
  }
  .contentMsgCss:after { // 使 contentMsgCss 适应内部高度
    clear both
    content ''
    width 0
    height 0
    display block
    visibility hidden
  }
}
</style>
